<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div
									class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ durumAciklamasi }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<div class="grid dashboard">
						<div class="col">
							<div class="card overview-box flex flex-column pt-2 cyan-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-shopping-cart"></i>
									<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Teklif Kimliği</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-normal">{{mainData.teklifNo}}</span>
										<span class="overview-status p-1 fs-small">Revize No: {{mainData.revizeNo}}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="col">
							<div class="card overview-box flex flex-column pt-2 orange-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-dollar"></i>
									<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Satış Projesi</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-normal">{{mainData.satisProjesiName}}</span>
										<span class="overview-status p-1 fs-small">{{mainData.fiyatListesiName}}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="col">
							<div class="card overview-box flex flex-column pt-2 green-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-dollar"></i>
									<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">İndirim Öncesi Tutar</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-large">{{formatNumber(mainData.araToplamYeniBirimFiyat)}}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="col">
							<div class="card overview-box flex flex-column pt-2 bluegrey-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-dollar"></i>
									<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">İndirim</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-large">{{formatNumber(mainData.indirim1)}}</span>
										<span class="overview-status p-1 fs-small">İndirim Oranı: %{{mainData.indirim1yuzde}}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="col">
							<div class="card overview-box flex flex-column pt-2 purple-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-dollar"></i>
									<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Navlun Öncesi Tutar</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-large">{{formatNumber(mainData.navlunOncesiTutar)}}</span>
										<span class="overview-status p-1 fs-small">Navlun Tutarı: {{formatNumber(mainData.navlunMasrafi)}}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="col">
							<div class="card overview-box flex flex-column pt-2 pink-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-users"></i>
									<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Genel Toplam</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-large">{{formatNumber(mainData.genelToplam)}}</span>
										<span class="overview-status p-1 fs-small" v-if="mainData.urunAilesiName == 'STUDER'" >Tezgah Fiyati: {{formatNumber(mainData.tezgahFiyati)}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="durumAciklamasi == 'Teklif Kaybedildi'" class="grid">
			<div class="col-12">
				<div class="card" style="background-color: #ff6b6b; color:white">
					<h5>KAYBEDİLDİ</h5>
					<div class="grid">
						<div class="col-2">
							<div class="field p-fluid">
								<strong>Kapatıldığı Tarih</strong>
								<p>{{ kapanisData.kapatildigiTarih }}</p>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<strong>Gerçek Gelir</strong>
								<p>{{ formatNumber(kapanisData.gercekGelir) }}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<strong>Kapanış Açıklama</strong>
								<p>{{ kapanisData.kapanisAciklama }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="durumAciklamasi == 'Teklif Kazanıldı'" class="grid">
			<div class="col-12">
				<div class="card" style="background-color: #1dd1a1; color:white">
					<h5>KAZANILDI</h5>
					<div class="grid">
						<div class="col-2">
							<div class="field p-fluid">
								<strong>Kapatıldığı Tarih</strong>
								<p>{{ kapanisData.kapatildigiTarih }}</p>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<strong>Gerçek Gelir</strong>
								<p>{{ formatNumber(kapanisData.gercekGelir) }}</p>
							</div>
						</div>
						<div class="col-8">
							<div class="field p-fluid">
								<strong>Kapanış Açıklama</strong>
								<p>{{ kapanisData.kapanisAciklama }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<h5>FİRMA</h5>
					<div class="grid">
						<div class="col-9">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma" label="Firma"
									entityName="account" nameField="name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.firmaName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="ilgilikisi" v-model="mainData.ilgiliKisiName" ref="entity_ilgilikisi" label="İlgili Kişi"
									entityName="contact" nameField="fullname" :state="true" :required="true" :disabled="isDisabled"
									parentFieldName="parentcustomerid" :parentId="firmaSelected"
									@itemSelected="ilgiliKisiSelected = $event"
									@itemCleared="ilgiliKisiSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.ilgiliKisiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.ilgiliKisiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="card">
					<h5>TEKLİF DETAYLARI</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifTarihi">Teklif Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="teklifTarihi" v-model="mainData.teklifTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.teklifTarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.teklifTarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="gecerlilikTarihi">Geçerlilik Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="gecerlilikTarihi" v-model="mainData.gecerlilikTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.gecerlilikTarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.gecerlilikTarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="satisYontemi">Satış Yöntemi <span style="color:red">*</span></label>
								<Dropdown id="satisYontemi" v-model="mainData.satisYontemi" :options="SM_satisYontemi" :disabled="isDisabled"
									optionLabel="Value" optionValue="AttributeValue" placeholder="Satış Yöntemi" @change="changeSatisYontemi"  />
								<span v-if="v$.mainData.satisYontemi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.satisYontemi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookupPopup  id="tezgah" v-model="mainData.tezgahName" ref="entity_tezgah" 
									label="Tezgah" entityName="bm_unite" nameField="bm_serino" :state="true" :disabled="mainData.satisYontemi == 2 || isDisabled == true"
									@itemSelected="tezgahSelected = $event" @itemCleared="tezgahSelected = null" :required="mainData.satisYontemi != 2" 
									:CrmDataTable_options="tezgah_CrmDataTable_options" secondNameField="bm_urunid" secondNameFieldType="lookup">
								</EntityLookupPopup>
								<span v-if="v$.mainData.tezgahId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunAilesi">Ürün Ailesi <span style="color:red">*</span></label>
								<TreeSelect id="urunAilesi" v-model="selectedUrunAilesi"
									:options="urunAilesiNodes.jsonData" placeholder="Seçiniz" :disabled="isDisabled"
									@node-select="urunAilesiSelect($event)"></TreeSelect>
								<span v-if="v$.mainData.paraBirimiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.paraBirimiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookupPopup id="urun" v-model="mainData.urunName" ref="entity_urun" label="Ürün"
									entityName="product" nameField="name" :state="true"  :disabled="mainData.satisYontemi == 1 || isDisabled == true" :required="mainData.satisYontemi != 1" 
									@itemSelected="urunSelected = $event" @itemCleared="urunSelected = null"
									parentFieldName="subjectid" :parentId="{ 'Value': mainData.urunAilesiId }" secondNameField="productnumber"
									:CrmDataTable_options="urun_CrmDataTable_options">
								</EntityLookupPopup>
								<span v-if="v$.mainData.urunName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.urunName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="adet">Adet <span style="color:red">*</span></label>
								<InputNumber id="adet" v-model="mainData.adet" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="1" :showButtons="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.adet.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.adet.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookupPopup id="satisprojesi" v-model="mainData.satisProjesiName" ref="entity_satisprojesi" label="Satış Projesi"
									entityName="opportunity" nameField="name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="satisprojesiSelected = $event" @itemCleared="satisprojesiSelected = null"
									parentFieldName="customerid" :parentId="firmaSelected"
									:CrmDataTable_options="satisProjesi_CrmDataTable_options">
								</EntityLookupPopup>
								<span v-if="v$.mainData.satisProjesiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.satisProjesiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<label for="marka">Ürün Marka <span style="color:red">*</span></label>
								<Dropdown id="marka" v-model="mainData.urunMarka" :options="SM_Marka" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Marka" :filter="true" :disabled="true" />
								<span v-if="v$.mainData.urunMarka.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.urunMarka.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bolge">Bölge</label>
								<Dropdown id="bolge" v-model="mainData.bolge" :options="SM_bolge" optionLabel="Value" optionValue="AttributeValue" placeholder="Bölge" :disabled="true" />
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<label for="teslimSekli">Teslim Şekli <span style="color:red">*</span></label>
								<Dropdown id="teslimSekli" v-model="mainData.teslimSekli" :options="SM_teslimSekli"
									optionLabel="Value" optionValue="AttributeValue" placeholder="Teslim Şekli" :disabled="isDisabled"
									:showClear="true" :filter="true" />
								<span v-if="v$.mainData.teslimSekli.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.teslimSekli.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="teklifVerenKisi" v-model="mainData.teklifVerenKisiName" ref="entity_teklifVerenKisi" label="Teklif Veren Kişi"
									entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="teklifVerenKisiSelected = $event"
									@itemCleared="teklifVerenKisiSelected = null"
									secondNameField="bm_bolge" secondNameFieldType="picklist"
									parentFieldName="bm_anadepartman" :parentId="{'Value': 1000}"></EntityLookup>
								<span v-if="v$.mainData.teklifVerenKisiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.teklifVerenKisiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunTipi">Ürün Tipi</label>
								<Dropdown id="urunTipi" v-model="mainData.urunTipi" :options="SM_UrunTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Tipi" :filter="true" :disabled="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="poNo">PO No</label>
								<InputText id="poNo" type="text" v-model="mainData.tezgahPoNo" :disabled="mainData.satisYontemi == 1" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12" v-if="teklifUrunleriData.length > 0">
				<div class="card">
					<div class="flex justify-content-between">
						<div>
							<h5>TEKLİF ÜRÜNLERİ</h5>
						</div>
						<div>
							<Button icon="pi pi-file-excel" class="p-button-secondary" label="Excel'e Al" @click="OnExcelExport('xls')" />
						</div>
					</div>
					<DataTable
						:value="teklifUrunleriData"
						v-model:selection="selectedItem"
						dataKey="urunKodu"
						:rowHover="true"
						class="p-datatable-sm"
						rowGroupMode="subheader" groupRowsBy="urunTuru"
						sortMode="single" sortField="urunTuru" :sortOrder="-1" scrollable
						responsiveLayout="scroll">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<template #groupheader="slotProps">
							<img :alt="slotProps.data.urunTuru" :src="getUrunTuru(slotProps.data.urunTuru)" width="32" style="vertical-align: middle" class="mr-3" />
							<span class="image-text fw-700">{{slotProps.data.urunTuru}}</span>
						</template>
						<Column selectionMode="multiple" style="flex: 0 0 3rem" headerStyle="width: 3rem"></Column>
						<Column field="urunKodu" header="Ürün kodu" headerStyle="width:130px" style="flex: 0 0 10rem" sortable></Column>
						<Column field="urun" header="Ürün" sortable>
							<template #body="slotProps">
								<a class='lookuplink' @click="OnClickProductUrl(slotProps.data.urunId)">{{ slotProps.data.urun }}</a> 
							</template>
						</Column>
						<Column field="birimFiyati" headerStyle="width:120px;" sortable style="flex: 0 0 8rem">
							<template #header>
								<div class="flex-1 text-right">Birim Fiyatı</div>
							</template>
							<template #body="slotProps">
								<div class="flex-1 text-right">
									<span :class="styleAdet(slotProps.data.miktar)">{{formatNumber(slotProps.data.birimFiyati)}}</span>
								</div>
							</template>
						</Column>
						<Column field="miktar" headerStyle="width:70px;" style="flex: 0 0 6rem" sortable>
							<template #header>
								<div class="flex-1 text-center">Adet</div>
							</template>
							<template #body="slotProps">
								<div class="flex-1 text-center">
									<span :class="styleAdet(slotProps.data.miktar)">{{slotProps.data.miktar}}</span>
								</div>
							</template>
						</Column>
						<Column field="indirimsizBirimFiyat" headerStyle="width:120px;" style="flex: 0 0 8rem" sortable>
							<template #header>
								<div class="flex-1 text-right">
									Toplam Tutar
								</div>
							</template>
							<template #body="slotProps">
								<div class="flex-1 text-right">
									<span :class="styleAdet(slotProps.data.miktar)">{{formatNumber(slotProps.data.indirimsizBirimFiyat)}}</span>
								</div>
							</template>
						</Column>
					</DataTable>
				</div>
			</div>

			<div class="col-12">
				<Annotation objectTypeCode="1084" logicalName="quote" :objectId="entityId" ></Annotation>
			</div>
		</div>

		<Dialog header="Sipariş Oluştur" v-model:visible="displaySiparisOlustur" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" @show="showSiparisOlustur">
			<br/>
			
			<div class="grid">
				<div class="col-6">
					<div class="field p-fluid">
						<label for="kazanmaTarihi">Kazanma Tarihi</label>
						<Calendar showButtonBar id="kazanmaTarihi" v-model="dataSiparisOlustur.actualEnd" autocomplete="off" :showIcon="true" />
					</div>
				</div>
				<div class="col-6" v-if="dataSiparisOlustur.firsatiKapat">
					<div class="field p-fluid">
						<label for="gercekGelir">Gerçek Gelir</label>
						<InputNumber id="gercekGelir" v-model="dataSiparisOlustur.actualrevenue" mode="decimal" locale="tr-TR" :minFractionDigits="2" placeholder="Gerçek Gelir" />
					</div>
				</div>
				<div class="col-12">
					<div class="field p-fluid">
						<label for="aciklama">Açıklama</label>
						<Textarea id="aciklama" rows="2" :autoResize="false" v-model="dataSiparisOlustur.description" placeholder="Açıklama" />
					</div>
				</div>
				<div class="col-12">
					<div class="field p-fluid">
						<InputSwitch id="firsatikapat" v-model="dataSiparisOlustur.firsatiKapat" :disabled="mainData.adet_teklif_etkin > 0" /> <label for="firsatikapat">Fırsatı Kapat</label>
						<Message severity="info" v-if="mainData.adet_teklif_etkin > 0">Teklife ait Satış Projesinin kapanabilmesi için bu tekliften başka taslak veya etkin durumda teklif olmaması gerekmekte.</Message>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Siparişi Oluştur" class="p-button-danger" icon="pi pi-save" @click="OnSiparisOlustur" />
			</template>
		</Dialog>

		<Dialog header="Teklifi Kapat" v-model:visible="displayTeklifiKapat" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" @show="showTeklifiKapat">
			<br/>
			
			<div class="grid">
				<div class="col-6">
					<div class="field p-fluid">
						<label for="durumAciklamasi">Durum Açıklaması</label>
						<Dropdown id="durumAciklamasi" v-model="quoteClose.statusCode" :options="SM_durumAciklamasi"
									optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Açıklaması"
									:showClear="true" :filter="false" />
					</div>
				</div>
				<div class="col-6">
					<div class="field p-fluid">
						<label for="kapanisTarihi">Kapanış Tarihi</label>
						<Calendar showButtonBar id="kapanisTarihi" v-model="quoteClose.actualEnd" autocomplete="off" :showIcon="true" />
					</div>
				</div>
				<div class="col-12">
					<div class="field p-fluid">
						<label for="aciklama">Açıklama</label>
						<Textarea id="aciklama" rows="2" :autoResize="false" v-model="quoteClose.description" />
					</div>
				</div>
				<div class="col-12">
					<div class="field p-fluid">
						<div class="field-radiobutton">
							<RadioButton inputId="revizeDurum" name="city" :value="1" v-model="quoteClose.revizeDurum" />
							<label for="revizeDurum">Revize Et</label>
						</div>
						<div class="field-radiobutton">
							<RadioButton inputId="revizeDurum" name="city" :value="0" v-model="quoteClose.revizeDurum" />
							<label for="revizeDurum">Bu teklifi düzeltme</label>
						</div>
					</div>
				</div>
				<div class="col-12" v-if="quoteClose.revizeDurum == 0">
					<div class="field p-fluid">
						<InputSwitch id="firsatikapat" v-model="quoteClose.firsatiKapat" :disabled="mainData.adet_teklif_etkin > 0" /> <label for="firsatikapat">İlişkilendirilmiş Fırsatı Kapat</label>
						<Message severity="info" v-if="mainData.adet_teklif_etkin > 0">Teklife ait Satış Projesinin kapanabilmesi için bu tekliften başka taslak veya etkin durumda teklif olmaması gerekmekte.</Message>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Teklifi Kapat" class="p-button-danger" icon="pi pi-save" @click="OnClickFiyatTeklifiKaybedildi" />
			</template>
		</Dialog>

		<Dialog header="Fırsatı Kapat" v-model:visible="displayFirsatiKapat" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
			<br/>
			
			<div class="grid">
				<div class="col-12">
					<div class="field p-fluid">
						<div class="flex flex-wrap gap-3">
							<div class="flex align-items-center">
								<RadioButton inputId="durum" name="city" :value="1" v-model="dataFirsatiKapat.state" @change="changeOpportunityDurum"/>
								<label for="durum"> Kazanıldı</label>
							</div>
							<div class="flex align-items-center ml-4">
								<RadioButton inputId="durum" name="city" :value="2" v-model="dataFirsatiKapat.state" @change="changeOpportunityDurum" />
								<label for="durum"> Kaybedildi</label>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div class="field p-fluid">
						<label for="durumAciklamasi">Durum Açıklaması</label>
						<Dropdown id="durumAciklamasi" v-model="dataFirsatiKapat.statusCode" :options="SM_opportunity_statuscode(dataFirsatiKapat.state)"
									optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Açıklaması"
									:showClear="true" :filter="true" :disabled="dataFirsatiKapat.state == undefined"  />
					</div>
				</div>

				<div class="col-6">
					<div class="field p-fluid">
						<label for="gercekGelir">Gerçek Gelir</label>
						<InputNumber id="gercekGelir" v-model="dataFirsatiKapat.actualrevenue" mode="decimal" locale="tr-TR" :minFractionDigits="2" />
					</div>
				</div>

				<div class="col-6">
					<div class="field p-fluid">
						<label for="kapanisTarihi">Kapanış Tarihi</label>
						<Calendar showButtonBar id="kapanisTarihi" v-model="dataFirsatiKapat.actualEnd" autocomplete="off" :showIcon="true" />
					</div>
				</div>

				<div class="col-6" v-if="dataFirsatiKapat.state == 2">
					<div class="field p-fluid">
						<EntityLookup id="rakip" v-model="dataFirsatiKapat.competitor" ref="entity_rakip"
							label="Rakip" entityName="competitor" nameField="name"
							@itemSelected="rakipSelected = $event" @itemCleared="rakipSelected = null">
						</EntityLookup>
					</div>
				</div>

				<div class="col-12">
					<div class="field p-fluid">
						<label for="aciklama">Açıklama</label>
						<Textarea id="aciklama" rows="2" :autoResize="false" v-model="dataFirsatiKapat.description" />
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Fırsatı Kapat" class="p-button-danger" icon="pi pi-save" @click="OnClickFirsatiKapat" />
			</template>
		</Dialog>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 1084,
			mainData: {},
			selectedItem: [],
			teklifUrunleriData: [],
			displayTeklifiKapat: false,
			displaySiparisOlustur: false,
			dataSiparisOlustur: {},

			quoteClose: {},
			nestedMenuitems: [
				{
					label: 'Kaydet',
					icon: 'pi pi-fw pi-save',
					visible: () => !this.isDisabled,
					command: () => { this.OnSave(false); },
				},
				{
					label: 'Kaydet & Kapat',
					icon: 'pi pi-fw pi-save',
					visible: () => !this.isDisabled,
					command: () => { this.OnSave(true); },
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label: 'Tezgah Ekle',
					icon: 'pi pi-fw pi-plus-circle',
					visible: () => !this.isDisabled,
					command: () => { this.OnTezgahEkle(); },
				},
				{
					label: 'Teklif Yazdır',
					icon: 'pi pi-fw pi-print',
					command: () => { this.OnPrintQuote(); },
				},
				{
					label: 'Teklif Yazdır (İngilizce)',
					icon: 'pi pi-fw pi-print',
					command: () => { this.OnPrintQuote_en(); },
					visible: () => (this.profileData.guvenlikSeviyeleri.indexOf("Seviye A") > -1)
				},
				{
					label: 'Fiyat Teklifini Etkinleştir',
					icon: 'pi pi-fw pi-directions',
					visible: () => this.isFiyatTeklifiniEtkinlestirVisible(),
					command: () => { this.OnFiyatTeklifiniEtkinlestir(); },
				},
				{
					label: 'Sipariş Oluştur',
					icon: 'pi pi-fw pi-money-bill',
					visible: () => this.isSiparisOlusturVisible(),
					command: () => { this.displaySiparisOlustur = true },
				},
				{
					label: 'Fiyat Teklifini Kapat',
					icon: 'pi pi-fw pi-eye-slash',
					visible: () => this.isTeklifiKapatVisible(),
					command: () => { this.OnFiyatTeklifiKaybedildi(); },
				},
				{
					label: 'Teklifi Yeniden Aç',
					icon: 'pi pi-fw pi-clock',
					visible: () => this.isTeklifiYenidenAcVisible(),
					command: () => { this.OnFiyatTeklifiYenidenAc(); },
				},
			],
			selectedUrunAilesi: null, //{ "9e344a2f-2208-4fa3-b0e3-0f015bd25bc7": true },
			urunAilesiNodes: [],

			urun_CrmDataTable_options: {
				searchAttributes: ["productnumber", "name"],
            },

			tezgah_CrmDataTable_options: {
				searchAttributes: ["bm_serino", "bm_urunidname"],
				views: [
					{ 'viewName': '01) Demo/Stok Tezgahlar Mazak', 'savedQueryId': 'DF5E7DA1-A712-E611-BA98-E61F135C9177' },
					{ 'viewName': '02) Demo/Stok Tezgahlar Lazer', 'savedQueryId': 'E085A707-24B0-E711-8294-E41F136A9174' },
					{ 'viewName': '03a) Demo/Stok Tezgahlar Studer', 'savedQueryId': '018C0E5B-AC3C-EC11-85A3-000C2920F782' },
					{ 'viewName': '03b) Demo/Stok Tezgahlar Walter', 'savedQueryId': 'C195F775-AC3C-EC11-85A3-000C2920F782' },
					{ 'viewName': '04) Demo/Stok Tezgahlar Nomura', 'savedQueryId': 'ABE43A68-3F32-E211-82BA-000C290383F6' },
					{ 'viewName': '05) Demo/Stok Tezgahlar Komatech', 'savedQueryId': '2CF7B509-630F-E811-B78C-E41F136A9174' },
					{ 'viewName': 'Diğer Stoklar', 'savedQueryId': 'A18A9906-D32A-E911-8AD8-E41F136A9174' },
				],
				forceSavedQueryId: "DF5E7DA1-A712-E611-BA98-E61F135C9177",
				preventSystemViews: true,
				preventUserViews: true,
            },

			satisProjesi_CrmDataTable_options: {
				searchAttributes: ["name", "customeridname"],
            },

			displayFirsatiKapat: false,
			dataFirsatiKapat: {},
		}
	},
	async created() {
		this.crmService = new CrmService();

		console.log('created');
		window.addEventListener('scroll', this.handleScroll);
		
		this.OnLoad();
	},
	mounted: function () {
		
	},
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData() {
			return this.$store.getters.getProfile;
		},
		durumAciklamasi: function() {
			switch (this.mainData.durumAciklamasi) {
				case 1:
				case 930710000:
					return "Yeni Teklif"
				case 2:
					return "Teklif Etkinleştirildi"
				case 4:
					return "Teklif Kazanıldı"
				case 5:
					return "Teklif Kaybedildi"
				case 6:
					return "Teklif İptal Edildi"
				case 7:
					return "Teklif Revize Edildi"
				default:
					return "Teklif DURUM HATASI: " + this.mainData.durumAciklamasi;
			}
		},
		isDisabled: function() {

			let _yetkiVarmi = false;
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Teklifler');
					if (filtered.length > 0) {
						_yetkiVarmi = filtered[0].isWrite == true;
					}
				}
			}

			//Etkin || Kazanıldı || Kapalı
			let _kapalimi = this.mainData.durum == 1 || this.mainData.durum == 2 || this.mainData.durum == 3
			
			if (_yetkiVarmi == false || _kapalimi) {
				return true;
			}
			else {
				return false;
			}
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Teklifler');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		SM_satisYontemi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_satisyontemi');
		},
		SM_teslimSekli: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'freighttermscode');
		},
		SM_durum: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'statuscode');
		},
		SM_durumAciklamasi: function () {
			return [
				{"AttributeValue": 5, "Value": "Kaybedildi"},
				{"AttributeValue": 6, "Value": "İptal Edildi"},
				{"AttributeValue": 7, "Value": "Revize Edildi"},
			];
		},
		SM_Marka: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_urunmarka');
		},
		SM_UrunTipi: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_uruntipi');
		},
		SM_bolge: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bolge');
		},
		teklifVerenKisiSelected: {
			get: function () {
				if (this.mainData["teklifVerenKisiId"]) {
					return { "Value": this.mainData["teklifVerenKisiId"], "Name": this.mainData["teklifVerenKisiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["teklifVerenKisiId"] = null;
					this.mainData["teklifVerenKisiName"] = null;
				}
				else {
					this.mainData["teklifVerenKisiId"] = newValue.Value;
					this.mainData["teklifVerenKisiName"] = newValue.Name;
				}
			}
		},
		tezgahSelected: {
			get: function () {
				if (this.mainData["tezgahId"]) {
					return { "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] }
				} else { return null; }
			},
			set: function (newValue) {
				

				if (newValue == null) {
					this.mainData["tezgahId"] = null;
					this.mainData["tezgahName"] = null;
				}
				else {
					this.mainData["tezgahId"] = newValue.Value;
					
					if (newValue.Name == undefined) {
						this.mainData["tezgahName"] = 'İSİMSİZ';
					}
					else {
						this.mainData["tezgahName"] = newValue.Name;
					}
					

					this.crmService.GetEntityById({
						"entityName": "bm_unite",
						"entityId": newValue.Value,
						"columnNames": ["bm_urunid", "bm_po"]})
					.then(data => {
						debugger;
						if (data.jsonData != null) {
							console.log('111---------------------------');
							console.table(data.jsonData);


							this.$refs.entity_urun.setDefaultData({ "Value": data.jsonData["bm_urunid"], "Name": data.jsonData["bm_urunidName"] });
							this.mainData["urunId"] = data.jsonData["bm_urunid"];
							this.mainData["urunName"] = data.jsonData["bm_urunidName"];
							this.mainData["tezgahPoNo"] = data.jsonData["bm_po"];

							debugger;

							//Ürünün üzerindeki Ürün ailesini getir
							this.crmService.GetEntityById({
								"entityName": "product",
								"entityId": data.jsonData["bm_urunid"],
								"columnNames": ["subjectid"]})
							.then(data_product => {
								if (data_product.jsonData != null) {
									debugger;
									//this.$refs.entity_tezgah.setDefaultData({ "Value": data_product.jsonData["subjectid"], "Name": data_product.jsonData["subjectidName"] });
									let subjectIdKey = data_product.jsonData["subjectid"];
									let data1 = {};
									data1[subjectIdKey] = true;
									this.selectedUrunAilesi = data1;

									this.mainData["urunAilesiId"] = data_product.jsonData["subjectid"];
									this.mainData["urunAilesiName"] = data_product.jsonData["subjectidName"];
								}
							})
							.catch(error => console.log(error));
						}
					})
					.catch(error => console.log(error));
				}
			}
		},
		firmaSelected: {
			get: function () {
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;
				}

				this.ilgiliKisiSelected = null;
				this.mainData["ilgiliKisiId"] = null;
				this.mainData["ilgiliKisiName"] = null;
				this.$refs.entity_ilgilikisi.setDefaultData(null);

				this.satisprojesiSelected = null;
				this.mainData["satisProjesiId"] = null;
				this.mainData["satisProjesiName"] = null;
				this.$refs.entity_satisprojesi.setDefaultData(null);
			}
		},
		urunSelected: {
			get: function () {
				if (this.mainData["urunId"]) {
					return { "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["urunId"] = null;
					this.mainData["urunName"] = null;
				}
				else {
					debugger;
					this.mainData["urunId"] = newValue.Value;
					this.mainData["urunName"] = newValue.Name;

					this.crmService.GetEntityById({
						"entityName": "product",
						"entityId": newValue.Value,
						"columnNames": ["bm_urunmarka"]})
					.then(data => {
						if (data.jsonData != null) {
							console.log()
							debugger;
							this.mainData["urunMarka"] = parseInt(data.jsonData["bm_urunmarkaId"]);

							setTimeout(() => {
								this.OnSave();
							}, 500);
						}
					})
					.catch(error => console.log(error));
				}
			}
		},
		ilgiliKisiSelected: {
			get: function () {
				if (this.mainData["ilgiliKisiId"]) {
					return { "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["ilgiliKisiId"] = null;
					this.mainData["ilgiliKisiName"] = null;
				}
				else {
					this.mainData["ilgiliKisiId"] = newValue.Value;
					this.mainData["ilgiliKisiName"] = newValue.Name;
				}
			}
		},
		satisprojesiSelected: {
			get: function () {
				if (this.mainData["satisProjesiId"]) {
					return { "Value": this.mainData["satisProjesiId"], "Name": this.mainData["satisprojesiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["satisProjesiId"] = null;
					this.mainData["satisProjesiName"] = null;
				}
				else {
					this.mainData["satisProjesiId"] = newValue.Value;
					this.mainData["satisProjesiName"] = newValue.Name;
				}
			}
		},
		paraBirimiSelected: {
			get: function () {
				if (this.mainData["paraBirimiId"]) {
					return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiId"] = null;
					this.mainData["paraBirimiName"] = null;
				}
				else {
					this.mainData["paraBirimiId"] = newValue.Value;
					this.mainData["paraBirimiName"] = newValue.Name;

					var fiyatListesiObject = {};
					debugger;
					switch (newValue.Value.toUpperCase()) {
						case "4041F124-77B5-DD11-87AF-000E0C3F151F": //Dolar
							fiyatListesiObject = { "Value": "F0459251-77B5-DD11-87AF-000E0C3F151F", "Name": "Dolar Fiyat Listesi" };
							break;
						case "4F6220A0-6AEB-DC11-A742-000E0C3F151F": //Euro
							fiyatListesiObject = { "Value": "DF2D0066-69EC-DC11-B0AB-000E0C3F151F", "Name": "Euro Fiyat Listesi" };
							break;
						case "1B961545-8064-DD11-BD7D-000E0C3F151F": //İsviçre Frankı
							fiyatListesiObject = { "Value": "0B5A578E-8064-DD11-BD7D-000E0C3F151F", "Name": "CHF Fiyat Listesi" };
							break;
						case "1A9843B4-682D-E211-B7F3-000C290383F6": //Türk Lirası
							fiyatListesiObject = { "Value": "68A803D2-0F75-E211-9AD9-E61F135C9177", "Name": "TL Fiyat Listesi" };
							break;
						case "45D2328A-C01A-DD11-AD90-000E0C3F151F": //Yen
							fiyatListesiObject = { "Value": "4ED81A93-111C-DD11-AD90-000E0C3F151F", "Name": "Yen Fiyat Listesi" };
							break;
					}

					this.$refs.entity_fiyatListesi.setDefaultData(fiyatListesiObject);
					this.fiyatListesiSelected = fiyatListesiObject;
				}
			}
		},
		fiyatListesiSelected: {
			get: function () {
				if (this.mainData["fiyatListesiId"]) {
					return { "Value": this.mainData["fiyatListesiId"], "Name": this.mainData["fiyatListesiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["fiyatListesiId"] = null;
					this.mainData["fiyatListesiName"] = null;
				}
				else {
					this.mainData["fiyatListesiId"] = newValue.Value;
					this.mainData["fiyatListesiName"] = newValue.Name;
				}
			}
		},
		rakipSelected: {
			get: function () {
				if (this.dataFirsatiKapat["competitorId"]) {
					return { "Value": this.dataFirsatiKapat["competitorId"], "Name": this.dataFirsatiKapat["competitorName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.dataFirsatiKapat["competitorId"] = null;
					this.dataFirsatiKapat["competitorName"] = null;
				}
				else {
					this.dataFirsatiKapat["competitorId"] = newValue.Value;
					this.dataFirsatiKapat["competitorName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					let data = await this.crmService.getTeklifById(this.entityId);
					debugger;
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}

					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						this.mainData["entityId"] = this.entityId;
						this.teklifUrunleriData = data.teklifUrunleriData;

						if (this.mainData["tezgahId"]) {
							this.$refs.entity_tezgah.setDefaultData({ "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] });
						}

						if (this.mainData["urunAilesiId"]) {
							this.selectedUrunAilesi = { [this.mainData["urunAilesiId"]]: true };
						}

						if (this.mainData["firmaId"]) {
							this.$refs.entity_firma.setDefaultData({ "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] });
						}

						if (this.mainData["urunId"]) {
							this.$refs.entity_urun.setDefaultData({ "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] });
						}

						if (this.mainData["ilgiliKisiId"]) {
							this.$refs.entity_ilgilikisi.setDefaultData({ "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] });
						}

						
						if (this.mainData["satisProjesiId"]) {
							this.$refs.entity_satisprojesi.setDefaultData({ "Value": this.mainData["satisProjesiId"], "Name": this.mainData["satisProjesiName"] });
						}
	/*
						if (this.mainData["paraBirimiId"]) {
							this.$refs.entity_paraBirimi.setDefaultData({ "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] });
						}

						if (this.mainData["fiyatListesiId"]) {
							this.$refs.entity_fiyatListesi.setDefaultData({ "Value": this.mainData["fiyatListesiId"], "Name": this.mainData["fiyatListesiName"] });
						}
						*/

						if (this.mainData["teklifVerenKisiId"]) {
							this.$refs.entity_teklifVerenKisi.setDefaultData({ "Value": this.mainData["teklifVerenKisiId"], "Name": this.mainData["teklifVerenKisiName"] });
						}

						if (this.mainData["teklifTarihi"]) {
							this.mainData["teklifTarihi"] = new Date(this.mainData.teklifTarihi);
						}

						if (this.mainData["gecerlilikTarihi"]) {
							this.mainData["gecerlilikTarihi"] = new Date(this.mainData.gecerlilikTarihi);
						}
					}

					if (data.kapanisData.length > 0) {
						this.kapanisData = data.kapanisData[0];
					}

					this.urunAilesiNodes = await this.crmService.GetSubjects();

					this.urun_CrmDataTable_options["filterParent"] = [
						{ 'name': 'subjectid', 'filterOperator': 'eq', 'value': this.mainData.urunAilesiId },
					];

				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getTeklifUpdate(this.mainData);
					debugger;
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								if (window.opener) {
									window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
								}
								setTimeout(() => {
									window.close();
								}, 1000);
							}
							else {
								this.OnLoad();
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem('quote', this.entityId);
        },
		async OnFiyatTeklifiniEtkinlestir(){
			try {
				const response = await this.crmService.TeklifiEtkinlestir(this.entityId);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Teklif Etkinleştirilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Teklif Başarıyla Etkinleştirildi', life: 3000});
						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
						}
						
						setTimeout(() => {
							this.OnLoad();
						}, 500);
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
			}
		},
		async OnFiyatTeklifiKaybedildi(){
			this.displayTeklifiKapat = true;
			this.quoteClose.statusCode = 7;
			this.quoteClose.revizeDurum = 1;
		},
		async OnClickFiyatTeklifiKaybedildi(){
			try {
				if (this.quoteClose["statusCode"] == undefined) {
					this.$toast.add({severity:'error', summary: 'Hata', detail: 'Lütfen Durum Açıklaması seçiniz!', life: 3000});
					return false;
				}

				this.quoteClose["quoteId"] = this.entityId;
				if (this.quoteClose["revizeDurum"] == 1) {
					this.quoteClose["firsatiKapat"] = false
				}

				if (this.quoteClose.firsatiKapat == true) {
					this.displayFirsatiKapat = true;
					this.dataFirsatiKapat.actualEnd = new Date();
					this.dataFirsatiKapat.actualrevenue = 0;
				}
				else {
					const response = await this.crmService.TeklifKaybedildi(this.quoteClose);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Teklif Etkinleştirme', detail: response.hataAciklamasi, life: 5000});
						}else {
							
							debugger;

							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}

							if (window.opener) {
								window.opener.window.postMessage({'tag': 'opportunity_reload'}, window.location.origin);
							}

							if (response.olusturulanId != '00000000-0000-0000-0000-000000000000') { //Revize Edilmiş
								this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Revize edildi, YÖNLENDİRİLİYORSUNUZ', life: 3000});
								setTimeout(() => {
									let routeData = this.$router.resolve({ name: 'quote', params: { id: response.olusturulanId, name: 'Teklif' } });
									window.open(routeData.href, '_top');
								}, 3000);
								
							} else {
								this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Etkinleştirildi', life: 3000});
								this.displayTeklifiKapat = false;

								setTimeout(() => {
									this.OnLoad();
								}, 500);
							}
						}
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
			}
		},
		async OnSiparisOlustur(){
			try {
				debugger;
				
				this.dataSiparisOlustur["tezgahPoNo"] = this.mainData.tezgahPoNo;
				this.dataSiparisOlustur["opportunityId"] = this.mainData.satisProjesiId;
				this.dataSiparisOlustur["quoteId"] = this.entityId;

				const response = await this.crmService.SiparisOlustur(this.dataSiparisOlustur);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Sipariş Oluştur', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Sipariş Oluşturuldu', life: 3000});
						this.displayTeklifiKapat = false;

						if (window.opener) {
							window.opener.window.postMessage({'tag': 'opportunity_reload'}, window.location.origin);
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);

							debugger;
							if (window.opener.opener) {
								debugger;
								window.opener.opener.postMessage({'tag': 'refresh'}, window.location.origin);
							}
						}

						if (response.olusturulanId != "") {
							setTimeout(() => {
								this.$router.push({ name: 'salesorder', params: { id: response.olusturulanId } });
							}, 700);
						}
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
			}
		},
		async OnFiyatTeklifiYenidenAc(){
			try {
				const response = await this.crmService.FiyatTeklifiYenidenAc(this.entityId);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Teklifi Yeniden Aç', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Teklifi Başarıyla Yeniden Açıldı', life: 3000});
						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
						}
						
						setTimeout(() => {
							this.OnLoad();
						}, 500);
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
			}
		},
		OnPrintQuote(){
			//http://localhost:58700/api/teklif/teklifyazdir?id=152ecd43-8139-ed11-b532-000c2920f782
			window.open(process.env.VUE_APP_SERVICEURL + '/teklif/teklifyazdir/' + this.entityId, 'ssrsreport', '_blank');
		},
		OnPrintQuote_en(){
			//http://localhost:58700/api/teklif/teklifyazdir?id=152ecd43-8139-ed11-b532-000c2920f782
			window.open(process.env.VUE_APP_SERVICEURL + '/teklif/teklifyazdir_en/' + this.entityId, 'ssrsreport', '_blank');
		},
		OnExcelExport(fileType){
            let loader = this.$loading.show({
                container: this.$refs.mainContainer
            });

            this.crmService.GetSsrsReport('Teklif_yeni_liste', 'quoteId=' + this.entityId, fileType).then(responseSSRS => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                const url = window.URL.createObjectURL(responseSSRS.data);
                a.href = url;
                a.download = this.mainData.teklifNo + "." + fileType;
                a.click();

                a.remove();
                window.URL.revokeObjectURL(url);
                loader.hide();
            })
            .catch(error => {
                loader.hide();
                debugger;
                console.log(error);
            });
        },
		getUrunTuru(urunTuru){
			if (urunTuru == 'TEZGAH') {
				return "layout/images/entityImages/product.png";
			}
			else {
				return "layout/images/entityImages/bm_opsiyonpaketi.png";
			}
		},
		OnTezgahEkle(){
			debugger;
			//this.$router.push({ name: 'quotetezgahekle', params: { quoteId: this.entityId } });
			if(this.mainData.urunDurumu === 0) {
				let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

				let routeData = this.$router.resolve({ name: 'quotetezgahekle2', params: { quoteId: this.entityId } });
				window.open(routeData.href, '_blank', features);
			}
			else {
				this.$toast.add({severity:'error', summary: 'HATA', detail: 'Ürün devredışı bırakıldığı için düzenleme yapamazsınız', life: 3500});
			}
		},
		onRowSelectTeklif(event) {
			this.$router.push({ name: 'quote', params: { id: event.data.ID } });
		},
		urunAilesiSelect(event) {
			this.mainData["urunAilesiId"] = event.key;
			this.mainData["urunAilesiName"] = event.label;

			this.urun_CrmDataTable_options["filterParent"] = [
				{ 'name': 'subjectid', 'filterOperator': 'eq', 'value': event.key },
			];
		},
		changeSatisYontemi(event){
			debugger;
			console.log(event);
			if (event.value == 1) { //Stok Ürün
				this.urunSelected = null;
				this.$refs.entity_urun.setDefaultData(null);

				this.selectedUrunAilesi = null;

				this.mainData["urunAilesiId"] = null;
				this.mainData["urunAilesiName"] = null;
			}
			else{ //Yeni Ürün
				this.tezgahSelected = null;
				this.$refs.entity_tezgah.setDefaultData(null);

				this.urunSelected = null;
				this.$refs.entity_urun.setDefaultData(null);

				this.selectedUrunAilesi = null;

				this.mainData["urunAilesiId"] = null;
				this.mainData["urunAilesiName"] = null;
			}
		},
		showTeklifiKapat(){
			this.quoteClose.actualEnd = new Date();
		},
		changeOpportunityDurum() {
			if (this.dataFirsatiKapat.state == 1) { //Kazandı
				this.dataFirsatiKapat.statusCode = 3;
			}
			else if (this.dataFirsatiKapat.state == 2) { //Kaybetti
				this.dataFirsatiKapat.statusCode = null;
			}

			this.dataFirsatiKapat.competitorId = null;
			this.dataFirsatiKapat.competitorName = null;
		},
		async OnClickFirsatiKapat() {
			if (this.dataFirsatiKapat.statusCode == null) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: 'Lütfen Durum Açıklamasını doldurunuz!', life: 5000});
				return false;
			}

			if (this.dataFirsatiKapat.actualEnd == null) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: 'Lütfen Kapanış tarihini doldurunuz!', life: 5000});
				return false;
			}

			if (this.dataFirsatiKapat.state == 1) { //kazanıldı
				if (this.dataFirsatiKapat.actualrevenue == null) {
					this.$toast.add({severity:'error', summary: 'HATA', detail: 'Lütfen Gerçek Geliri doldurunuz!', life: 5000});
					return false;
				}
			}

			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});
			
			
			try {
				const response_teklif = await this.crmService.TeklifKaybedildi(this.quoteClose);
				if (response_teklif) {
					if (response_teklif.hata == true) {
						this.$toast.add({severity:'error', summary: 'Teklif Etkinleştirme', detail: response_teklif.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Teklif Başarıyla kapatıldı', life: 3000});
						this.displayTeklifiKapat = false;

						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
						}
					}
				}
				
				this.dataFirsatiKapat["opportunityId"] = this.mainData.satisProjesiId;
				const response_firsat = await this.crmService.OpportunityClose(this.dataFirsatiKapat);
				debugger;
				if (response_firsat) {
					if (response_firsat.hata == true) {
						this.$toast.add({severity:'error', summary: 'HATA', detail: response_firsat.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Satış Projesi Başarıyla Kapatıldı', life: 3000});
						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							
							debugger;
							if (window.opener.opener) {
								debugger;
								window.opener.opener.postMessage({'tag': 'refresh'}, window.location.origin);
							}
						}

						if (window.opener) {
							window.opener.window.postMessage({'tag': 'opportunity_reload'}, window.location.origin);
						}
						
						this.displayFirsatiKapat = false;
						this.dataFirsatiKapat = {};
						this.OnLoad();
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
			}
			finally{
				loader.hide();
			}
		},
		showSiparisOlustur(){
			this.dataSiparisOlustur.actualEnd = new Date();
			this.dataSiparisOlustur.actualrevenue = this.mainData.firsatKapanisGercekGelir;
			if (this.mainData.adet_teklif_etkin == 0) {
				this.dataSiparisOlustur.firsatiKapat = true;
			}
		},
		OnClickProductUrl(entityId){
            let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: "product", params: { id: entityId } });
			window.open(routeData.href, '_blank', 'yenipencere', features);
		},
		isTezgahEkleVisible(){
			return this.mainData["urunId"] != null && (this.mainData["durumAciklamasi"] == 1 || this.mainData["durumAciklamasi"] == 930710000);
		},
		isKaydetVisible(){
			return (this.mainData["durumAciklamasi"] == 1 || this.mainData["durumAciklamasi"] == 930710000);
		},
		isFiyatTeklifiniEtkinlestirVisible(){
			return (this.mainData["durumAciklamasi"] == 1 || this.mainData["durumAciklamasi"] == 930710000) && this.teklifUrunleriData.length > 0;
		},
		isSiparisOlusturVisible(){
			return this.mainData["durumAciklamasi"] == 2; //Teklif etkinleştirildi ise
		},
		isTeklifiKapatVisible(){
			return this.mainData["durumAciklamasi"] == 2; //Teklif etkinleştirildi ise
		},
		isTeklifiYenidenAcVisible(){
			//this.profileData.guvenlikSeviyeleri.indexOf("Seviye A") > -1 && 
			return this.mainData["durumAciklamasi"] != 930710000 && this.mainData["durumAciklamasi"] != 4;
		},
		SM_opportunity_statuscode(durum) {
			let durumAciklamasi = this.$store.getters.getStringMapByEntityId(3, 'statuscode');

			if (durum == 1) {
				durumAciklamasi = durumAciklamasi.filter(x => x.AttributeValue == 3);
			}
			else if (durum == 2) {
				durumAciklamasi = durumAciklamasi.filter(x => x.AttributeValue > 3);
				durumAciklamasi = durumAciklamasi.filter(x => x.AttributeValue != 930710000);
				//this.dataFirsatiKapat.statusCode = {};
			}

			return durumAciklamasi;
		},
		styleAdet(adet){
			if (adet > 0) {
				return "adet-badge dolu"
			}
			else if(adet < 0) {
				return "adet-badge eksiDeger"
			}
			else {
				return ""
			}
		},
		styleIndirim(data){
			if (data.miktar > 0) {
				return "adet-badge dolu";
			}else if (data.miktar < 0) {
				return "adet-badge eksiDeger";
			}
			else {
				return "";
			}
		},
		formatNumber(value) {
			if (value != undefined) {
				return value.toLocaleString('tr-TR') + ' ' + this.getCurrency();
			}
		},
		getCurrency() {
			let paraBirimi = '';
			switch (this.mainData.paraBirimiName) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;
			
				default:
					break;
			}
			return paraBirimi;
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Siparişler');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				firmaName: {
					required: helpers.withMessage('Firma Adı alanı dolu olmalıdır!', required),
				},
				ilgiliKisiName: {
					required: helpers.withMessage('İlgili Kişi alanı dolu olmalıdır!', required),
				},
				teklifTarihi: {
					required: helpers.withMessage('Teklif Tarihi alanı dolu olmalıdır!', required),
				},
				teklifVerenKisiName: {
					required: helpers.withMessage('Teklif Veren Kişi alanı dolu olmalıdır!', required),
				},
				satisYontemi: {
					required: helpers.withMessage('Satış Yöntemi alanı dolu olmalıdır!', required),
				},
				tezgahId: {
					required: helpers.withMessage('Tezgah alanı dolu olmalıdır!', requiredIf(function(value, vm) {
						if (vm.satisYontemi == 1) { //Stok Ürün
							return true;
						}
						else {
							return false;
						}
					})),
				},
				urunName: {
					required: helpers.withMessage('Ürün alanı dolu olmalıdır!', required),
				},
				adet: {
					required: helpers.withMessage('Adet alanı dolu olmalıdır!', required),
				},
				satisProjesiName: {
					required: helpers.withMessage('Satış Projesi alanı dolu olmalıdır!', required),
				},
				gecerlilikTarihi: {
					required: helpers.withMessage('Geçerlilik Tarihi alanı dolu olmalıdır!', required),
				},
				teslimSekli: {
					required: helpers.withMessage('Teslim Şekli alanı dolu olmalıdır!', required),
				},
				paraBirimiName: {
					required: helpers.withMessage('Para Birimi alanı dolu olmalıdır!', required),
				},
				urunMarka: {
					required: helpers.withMessage('Ürün Marka alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	.adet-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		font-weight: bold;
		font-size: 13px;
		letter-spacing: .3px;

		&.dolu {
			background: #B3E5FC;
			color: #23547B;
		}

		&.indirim {
			background: #FFCDD2;
			color: #C63737;
		}

		&.eksiDeger {
			background: #C63737;
			color: #FFFFFF;
		}
	}

	.test1 {
		width: 250px;
		text-align: 'right';
		color:green ;
	}

	::v-deep(.lookuplink:hover) {
        text-decoration: underline;
        color: rgb(0, 0, 0);
		cursor: pointer;
    }
</style>
